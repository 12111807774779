<template>
  <v-dialog v-model="dialog" persistent max-width="600px" v-if="exampleFile">
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder text-capitalize">{{ label }}</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="">
          <v-alert
            dense
            border="left"
            type="warning"
            dismissible
            v-if="exampleFile.need_to_show_message"
          >
            <span v-html="exampleFile.message"></span>
          </v-alert>
        </div>
        <v-card elevation="0" class="mb-4">
          <form id="createForm" class="pa-3 text-center">
            <v-file-input
              v-model="formData.file"
              label="File"
              outlined
              dense
              :error-messages="fileErrors"
              @input="$v.formData.file.$touch()"
              @blur="$v.formData.file.$touch()"
            ></v-file-input>
            <!-- add example file -->
            <v-btn class="primary--text" outlined text :href="exampleFile.url">
              Download Template
            </v-btn>
            <v-main class="text-center mt-4">
              <button
                type="button"
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitEditForm"
              >
                Import
              </button>
            </v-main>
          </form>
        </v-card>
        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "ImportData",
  mixins: [validationMixin],
  validations: {
    formData: {
      file: { required },
    },
  },
  props: ["type", "label", "setData", "importType"],
  data: () => ({
    dialog: false,

    formData: {
      file: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      }
      this.dialog = !this.dialog;
    },
    validateData() {
      return true;
    },
    convertToFormData() {
      let data = new FormData();

      if (this.importType == "create") {
        data.append("type", this.type);
      } else {
        data.append("type", this.type + "_update");
      }

      data.append("file", this.formData.file, this.formData.file.name);
      data.append(
        "warehouse_id",
        this.$store.getters.getWAREHOUSESSelectedWarehouseId
      );
      // if (this.type == "zone_group") {
      //   data.append(
      //     "warehouse_id",
      //     this.$store.getters.getWAREHOUSESSelectedWarehouseId
      //   );
      // } else if (this.type == "zone") {
      //   data.append(
      //     "warehouse_id",
      //     this.$store.getters.getWAREHOUSESSelectedWarehouseId
      //   );
      //   data.append(
      //     "zone_group_id",
      //     this.$store.getters.getWAREHOUSESSelectedZoneGroupId
      //   );
      // } else if (this.type == "location") {
      //   data.append(
      //     "warehouse_id",
      //     this.$store.getters.getWAREHOUSESSelectedWarehouseId
      //   );
      //   data.append(
      //     "zone_group_id",
      //     this.$store.getters.getWAREHOUSESSelectedZoneGroupId
      //   );
      //   data.append("zone_id", this.$store.getters.getWAREHOUSESSelectedZoneId);
      // }
      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = this.convertToFormData();
      ApiService.post("/warehouse_management/warehouses/upload", data)
        .then(() => {
          // Swal.fire({
          //   title: "Imported",
          //   text: `Data is imported!`,
          //   icon: "success",
          //   showConfirmButton: false,
          //   timer: 2500,
          // });

          this.importComplete();

          // Swal.fire({
          //   title: `<strong>${res.data.data.success} of ${res.data.data.total} imported!</strong>`,
          //   icon: "info",
          //   html: `<div class='text-left'>${message}</div>`,
          // });
        })
        .catch(() => {
          // Swal.fire({
          //   title: "Error",
          //   text: `${res.data.message}`,
          //   icon: "error",
          //   showConfirmButton: false,
          //   timer: 2500,
          // });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    importComplete() {
      this.setData();
      this.toggleModal();
      this.resetEditForm();
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData.file = null;
    },
  },
  computed: {
    fileErrors() {
      return this.handleFormValidation("file", this);
    },
    exampleFile() {
      // let file = {
      //   type: "increments",
      //   url: "https://apitest.iqfulfillment.com/files/increments/increment.xlsx?v=1656928804",
      //   message:
      //     "The Excel template has been updated. Please, be sure to download a new one.",
      //   need_to_show_message: false,
      // };
      let file = null;
      if (this.type == "warehouse") {
        file =
          this.$store.getters.getWAREHOUSESExampleFiles.warehouse_sample_file;
      } else if (this.type == "zone_group") {
        file =
          this.$store.getters.getWAREHOUSESExampleFiles.zone_group_sample_file;
      } else if (this.type == "zone") {
        file = this.$store.getters.getWAREHOUSESExampleFiles.zone_sample_file;
      } else if (this.type == "location") {
        file =
          this.$store.getters.getWAREHOUSESExampleFiles.location_sample_file;
      }
      return file;
    },
  },
};
</script>
