import Swal from "sweetalert2";

const swalCountry = (message) => {
  Swal.fire({
    title: `${message}`,
    // text: `${message}`,
    icon: "warning",
    confirmButtonClass: "btn btn-primary",
    // showConfirmButton: false,
    // timer: 2500,
  });
};

export default swalCountry;
