<template>
  <div>
    <div class="">
      <h4 class="pt-4">Organization</h4>
      <div class="d-block d-sm-flex justify-space-between pb-4">
        <div>
          <div class="d-flex">
            <span>Name:</span>
            <h5 class="pl-2">{{ detailData.organization.name }}</h5>
          </div>
          <div class="d-flex">
            <span>TRN:</span>
            <h6 class="pl-2">{{ detailData.organization.trn }}</h6>
          </div>
        </div>
        <div>
          <div>{{ detailData.organization.address.address }}</div>
          <div>{{ detailData.organization.address.city }}</div>
          <div>{{ detailData.organization.address.country }}</div>
        </div>
      </div>
      <div class="d-flex pt-4">
        <span>Warehouse type:</span>
        <h6 class="pl-2">{{ detailData.warehouse_type.title }}</h6>
      </div>
      <div class="d-flex">
        <span>System code:</span>
        <h6 class="pl-2">{{ detailData.system_code }}</h6>
      </div>
      <div class="d-flex">
        <span>Warehouse name:</span>
        <h6 class="pl-2">{{ detailData.warehouse_name }}</h6>
      </div>
      <div class="d-flex">
        <span>Description:</span>
        <h6 class="pl-2">{{ detailData.description }}</h6>
      </div>
      <div>
        <h4 class="pt-4">Address</h4>
        <div>
          <div class="d-flex">
            <span>Country:</span>
            <h6 class="pl-2" v-if="detailData.country">
              {{ detailData.country.title }}
            </h6>
          </div>
          <div class="d-flex">
            <span>State:</span>
            <h6 class="pl-2" v-if="detailData.state_id">
              {{ detailData.state_id.title }}
            </h6>
          </div>
          <div class="d-flex">
            <span>City:</span>
            <h6 class="pl-2" v-if="detailData.city_id">
              {{ detailData.city_id.title }}
            </h6>
          </div>
          <div class="d-flex">
            <span>Area:</span>
            <h6 class="pl-2" v-if="detailData.area_id">
              {{ detailData.area_id.title }}
            </h6>
          </div>
          <div class="d-flex">
            <span>Address:</span>
            <h6 class="pl-2">{{ detailData.address }}</h6>
          </div>
          <div class="d-flex">
            <span>Address 2:</span>
            <h6 class="pl-2">{{ detailData.address_2 }}</h6>
          </div>
          <div class="d-flex">
            <span>Postal code:</span>
            <h6 class="pl-2">{{ detailData.postal_code }}</h6>
          </div>
        </div>
      </div>

      <div>
        <h4 class="pt-4">Contact</h4>
        <div>
          <div class="d-flex">
            <span>Name:</span>
            <h6 class="pl-2">{{ detailData.contact_name }}</h6>
          </div>
          <div class="d-flex">
            <span>Phone:</span>
            <h6 class="pl-2">{{ detailData.contact_phone }}</h6>
          </div>
          <div class="d-flex">
            <span>Email:</span>
            <h6 class="pl-2">{{ detailData.contact_email }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Warehouse.vue",
  props: {
    detailData: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    // detailData: {
    //   id: 10,
    //   organization: {
    //     name: "IQF",
    //     trn: null,
    //     address: {
    //       address: "WAREHOUSE NO. 5, PLOT NO. 5981298",
    //       city: "Dubai",
    //       country: "United Arab Emirates",
    //     },
    //   },
    //   warehouse_type: {
    //     id: 1,
    //     title: "Normal",
    //   },
    //   system_code: "TAD",
    //   warehouse_name: "Warehouse address updated",
    //   description: "My DESCRIPTION.",
    //   country: {
    //     id: 229,
    //     title: "United Arab Emirates",
    //     systemCode: "UAE",
    //     shortName: "AE",
    //     currency: null,
    //     phoneCode: "971",
    //     postalCodeRequired: 0,
    //   },
    //   city_id: {
    //     id: 48468,
    //     title: "Dubai",
    //     systemCode: "DXB",
    //     shortName: null,
    //   },
    //   state_id: {
    //     id: 4182,
    //     title: "Dubai",
    //     systemCode: "DXB",
    //     shortName: null,
    //   },
    //   area_id: {
    //     id: 951,
    //     title: "ABU HAIL",
    //   },
    //   address: "new address",
    //   address_2: null,
    //   postal_code: "Postal Code",
    //   contact_name: "Jhon",
    //   contact_phone: "+9717823182",
    //   contact_email: "jhotest@gmail.com",
    // },
  }),
};
</script>
