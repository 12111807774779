import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  GET_LOCATIONS,
  GET_ZONES,
  GET_ZONEGROUPS,
  SET_SELECTED_WAREHOUSE_ID,
  SET_SELECTED_ZONEGROUP_ID,
  SET_SELECTED_ZONE_ID,
  GET_WAREHOUSES,
} from "@/core/services/store/warehouses.module";
import swalCountry from "@/core/services/AlertServices/SwalCountry";

export default {
  data: () => ({
    warehouseRef: null,
    zoneGroupRef: null,
    zoneRef: null,
  }),
  mounted() {
    this.$store.dispatch(GET_WAREHOUSES).finally(() => {
      this.$store.commit(SET_PAGE_LOADING, false);
    });
  },
  methods: {
    handleAddFunction(itemType) {
      if (itemType === "warehouse") this.$refs.addWarehouseDialog.toggleModal();
      else if (itemType === "zone_group") {
        if (!this.$store.getters.getWAREHOUSESSelectedWarehouseId) {
          swalCountry("Please, select warehouse");
        } else this.$refs.addZoneGroupDialog.toggleModal();
      } else if (itemType === "zone") {
        if (!this.$store.getters.getWAREHOUSESSelectedZoneGroupId) {
          swalCountry("Please, select zone group");
        } else this.$refs.addZoneDialog.toggleModal();
      } else {
        // we need to get area label to check it also, because if its value is `area is not found`,
        // our areas array in vuex is still be empty and when we check its length
        // without checking label it will be true, we will get warning alert,
        // but if area label is `Area not found` we have to get access adding area
        if (!this.$store.getters.getWAREHOUSESSelectedZoneId) {
          swalCountry("Please, select zone");
        } else this.$refs.addLocationDialog.toggleModal();
      }
    },
    getZoneGroups(id, event) {
      let elem;
      if (event.target.firstElementChild) elem = event.target;
      else elem = event.target.parentElement.parentElement;
      this.$store.commit(SET_SELECTED_WAREHOUSE_ID, id);
      this.$store.commit(SET_SELECTED_ZONEGROUP_ID, null);
      this.$store.commit(SET_SELECTED_ZONE_ID, null);
      this.$store.commit(SET_PAGE_LOADING, true);
      if (this.warehouseRef) {
        this.warehouseRef.classList.remove("bg-light-info");
        this.warehouseRef.firstElementChild.firstElementChild.style.color =
          "rgba(0, 0, 0, 0.54)";
      }
      let payload = { warehouse_id: id, type: "zone_group" };
      this.$store.dispatch(GET_ZONEGROUPS, payload).finally(() => {
        elem.classList.add("bg-light-info");
        elem.firstElementChild.firstElementChild.style.color = "#8950fc";
        this.warehouseRef = elem;
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
    getZones(id, event) {
      let elem;
      if (event.target.firstElementChild) elem = event.target;
      else elem = event.target.parentElement.parentElement;
      this.$store.commit(SET_SELECTED_ZONEGROUP_ID, id);
      this.$store.commit(SET_SELECTED_ZONE_ID, null);
      this.$store.commit(SET_PAGE_LOADING, true);
      if (this.zoneGroupRef) {
        this.zoneGroupRef.classList.remove("bg-light-info");
        this.zoneGroupRef.firstElementChild.firstElementChild.style.color =
          "rgba(0, 0, 0, 0.54)";
      }
      let payload = {
        warehouse_id: this.$store.getters.getWAREHOUSESSelectedWarehouseId,
        zone_group_id: id,
        type: "zone",
      };
      this.$store.dispatch(GET_ZONES, payload).finally(() => {
        elem.classList.add("bg-light-info");
        elem.firstElementChild.firstElementChild.style.color = "#8950fc";
        this.zoneGroupRef = elem;
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
    getLocations(id, event) {
      let elem;
      if (event.target.firstElementChild) elem = event.target;
      else elem = event.target.parentElement.parentElement;
      this.$store.commit(SET_SELECTED_ZONE_ID, id);
      this.$store.commit(SET_PAGE_LOADING, true);
      if (this.zoneRef) {
        this.zoneRef.classList.remove("bg-light-info");
        this.zoneRef.firstElementChild.firstElementChild.style.color =
          "rgba(0, 0, 0, 0.54)";
      }
      let payload = {
        warehouse_id: this.$store.getters.getWAREHOUSESSelectedWarehouseId,
        zone_group_id: this.$store.getters.getWAREHOUSESSelectedZoneGroupId,
        zone_id: id,
        type: "location",
      };
      this.$store.dispatch(GET_LOCATIONS, payload).finally(() => {
        elem.classList.add("bg-light-info");
        elem.firstElementChild.firstElementChild.style.color = "#8950fc";
        this.zoneRef = elem;
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
  },
  computed: {
    tableProperties: function () {
      return {
        title: "Warehouses",
        description: "Manage Warehouses",
      };
    },
  },
};
