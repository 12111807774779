<template>
  <div class="p-4 pb-8 bg-white rounded-lg">
    <div class="mb-7 mx-4 mt-6 d-flex align-center justify-space-between">
      <datatable-detail
        class="col-9 col-md-9 col-sm-9"
        :table_properties="tableProperties"
      ></datatable-detail>
    </div>
    <div
      class="custom-container-height w-100 mx-0 pb-3 px-0 px-sm-2 px-md-8 d-flex flex-wrap poppins"
    >
      <NestedDataTable
        tableName="warehouses"
        editType="warehouse"
        :handleAddFunction="() => handleAddFunction('warehouse')"
        :handleGetFunction="getZoneGroups"
        :showExportSideBar="showExportSideBar"
      />
      <NestedDataTable
        tableName="zone groups"
        editType="zone_group"
        :handleAddFunction="() => handleAddFunction('zone_group')"
        :handleGetFunction="getZones"
        :showExportSideBar="showExportSideBar"
      />
      <NestedDataTable
        tableName="zones"
        editType="zone"
        :handleAddFunction="() => handleAddFunction('zone')"
        :handleGetFunction="getLocations"
        :showExportSideBar="showExportSideBar"
      />
      <NestedDataTable
        tableName="locations"
        editType="location"
        :handleAddFunction="() => handleAddFunction('location')"
        :showExportSideBar="showExportSideBar"
      />
    </div>
    <AddLocation ref="addLocationDialog" />
    <AddZone ref="addZoneDialog" />
    <AddZoneGroup ref="addZoneGroupDialog" />
    <AddWarehouse ref="addWarehouseDialog" />
    <ExportSidebar
      ref="exportSidebar"
      :exportHeaders="getExportColumns"
      :downloadItem="downloadItem"
      :submitFilterValues="() => {}"
      :resetFilterValues="() => {}"
      :filters="[]"
      :setTableFiltersValues="() => {}"
    />
  </div>
</template>

<script>
import ExportSidebar from "@/own/components/sidebars/ExportSidebar.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import warehouseNestedDataMixin from "@/own/mixins/warehouseNestedDataMixin";
import AddLocation from "@/own/components/warehouseManagement/warehouses/Add/AddLocation";
import AddZone from "@/own/components/warehouseManagement/warehouses/Add/AddZone";
import AddZoneGroup from "@/own/components/warehouseManagement/warehouses/Add/AddZoneGroup";
import AddWarehouse from "@/own/components/warehouseManagement/warehouses/Add/AddWarehouse";
import NestedDataTable from "@/own/components/warehouseManagement/warehouses/NestedDataTable";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "NestedData",
  components: {
    NestedDataTable,
    AddWarehouse,
    AddZoneGroup,
    AddZone,
    AddLocation,
    DatatableDetail,
    ExportSidebar,
  },
  mixins: [warehouseNestedDataMixin],
  data: () => ({
    exportColumns: [],
    exportType: null,
  }),
  computed: {
    getExportColumns() {
      return this.exportColumns;
    },
  },
  methods: {
    showExportSideBar(columns, type) {
      this.exportColumns = columns;
      this.type = type;
      this.$nextTick(() => {
        this.$refs.exportSidebar.$refs.export.initiate();
        this.$refs.exportSidebar.$data.exportSidebar = true;
      });
    },
    downloadItem(columns) {
      if (columns.length == 0) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { type: this.type, columns: columns };
      ApiService.post("/warehouse_management/warehouses/export", data).then(
        (response) => {
          const link = document.createElement("a");
          link.href = response.data.url;
          link.download = "ProblemWithExport.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
          this.$refs.exportSidebar.$refs.export.toggleModal();
          // this.$refs.exportSidebar.$data.exportSidebar = false;
          link.remove();
        }
      );
    },
  },
};
</script>

<style scoped>
.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 327px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 300px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 285px) !important;
  }
}

@media (max-width: 768px) {
  .custom-container-height {
    height: calc(100vh - 275px) !important;
  }
}
</style>
